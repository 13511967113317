* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
  
}

/* Main page wrapper */

.btn-outline-primary {
  --bs-btn-color: #0b41cd;
  --bs-btn-border-color: #0b41cd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b41cd;
  --bs-btn-hover-border-color: #0b41cd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0b41cd;
  --bs-btn-active-border-color: #0b41cd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  --bs-btn-disabled-color: #0b41cd;
  --bs-btn-disabled-bg: #0000;
  --bs-btn-disabled-border-color: #0b41cd;
  --bs-gradient: none;
}

.container {
  max-width: 90%;
  margin: 0 auto;
}

.bg-primary, .btn-primary {
  background-color: #0b41cd !important; 
}
.Mui-active,
.Mui-completed,
.Mui-checked {
  color: #0b41cd !important; 
}

.btn-primary:hover{
  background-color: #022366 !important; 
}

.column-settings-search {
  position: relative;
  flex: 1;
}

.column-settings-search-input {
  border: 1px solid #c2bab5;
  border-radius: 2px;
  padding: 2px 6px;
  width: 100%;
  padding-left: 36px !important;
}

.column-settings-accordian-container {
  height: 450px;
  overflow-y: auto;
  padding: 0 1rem;
}

.column-settings-accordion {
  border: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid #c2bab5 !important;
  border-radius: 0 !important;
}

.column-settings-accordion::before {
  display: none;
}

.column-settings-details {
  margin-left: 20px;
}

.column-settings-option {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #c2bab5;
  margin-bottom: 16px;
}

.column-settings-option-data {
  flex: 1;
  display: flex;
  cursor: pointer;
  align-items: center;
}

.node-settings-accordion {
  margin-bottom: 0px !important;
  border-bottom: 1px solid #c2bab5 !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}

.node-settings-hub-accordion {
  border: 1px solid #c2bab5 !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}

.node-settings-accordion-title {
  font-size: 17px;
  font-weight: 600;
}

.node-settings-hub-accordion-title {
  font-size: 15px;
}

.node-settings-label {
  font-size: 16px;
}

.node-settings-readonly-textfield {
  border: 1px solid gray;
  border-radius: 4px;
  width: 40vw;
  padding: 8px;
  margin: 6px 0px;
}

.node-settings-accordion-column-selection>input {
  opacity: 0;
}

.node-settings-accordion-column-selection>label {
  color: #0b41cd;
  float: right;
  cursor: pointer;
  text-decoration: underline;
}

.custom-dropdown-styles {
  background-color: white !important;
  color: black !important;
  text-align: left;
  width: 40vw !important;
  border-color: gray !important;
}

.custom-dropdown-styles::after {
  float: right !important;
  margin-top: 10px !important;
}

.custom-dropdown-styles:hover {
  background-color: white !important;
  color: black !important;
}

.rename-form {
  width: 40vw !important
}

.rename-form-helper-text {
  color: gray;
  font-size: 13px;
  padding-left: 15px;
  padding-top: 5px;
}

.custom-checkbox {
  max-height: 300px;
  border: 1px solid #ced4da;
  overflow-y: auto;
}

.custom-checkbox-title {
  padding-left: 10px;
  color: #6c757d;
}

.global-spinner {
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: 100%;
  z-index: 15000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.column-settings-option-data-text {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 530px;
}


.column-settings-option-data-text.open {
  white-space: unset;
}


.column-settings-option-data-icon {
  color: #544f4f;
}

.column-settings-option-data-icon.open {
  transform: rotate(180deg);
}

.column-settings-textarea {
  width: 100%;
  border: 1px solid #c2bab5;
  border-radius: 2px;
  font-family: "Roche Sans Light";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 12px 12px 16px px;
  letter-spacing: 0.2px;
  text-decoration: none;
  margin-top: 10px;
}

.column-settings-textarea-helper-text {
  font-family: "Roche Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #706b69;
}

.column-settings-title {
  display: flex;
  align-items: center;
  width: 100%;
}

.column-settings-title-checkbox {
  color: #0b41cd !important;
}

.column-settings-title-text {
  margin-bottom: 0 !important;
  color: #544f4f;
}

.column-settings-title-type {
  margin-bottom: 0 !important;
  font-size: 12px;
  flex: 1;
  text-transform: uppercase;
  color: #0b41cd;
  margin-left: 10px;
}

.column-settings-search-input::after,
.column-settings-search-input::before {
  display: none;
}

.column-settings-search-icon {
  position: absolute;
  height: 100%;
  left: 12px;
  width: 19px;
}

.column-settings-apply-button {
  display: none !important;
  font-family: "RocheRegular";
}

.MuiTypography-root .MuiTypography-body1 {
  font-family: "Roche Sans Light";
  font-size: 16px;
}

.Mui-expanded .column-settings-apply-button {
  display: inline-flex !important;
  text-transform: none;
  font-family: "RocheRegular";
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
}
.main-content-top-cancel-icon {
  cursor: pointer;
}
.cancel-icon-hidden {
  visibility: hidden;
}
.column-settings-helper-text {
  margin: 0px;
}
.main_page_top .pagination-wrapper {
  justify-content: start;
}
.pagination-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
}

.pagination-text-field {
  width: 70px;
  height: 36px;
  margin: 0 15px;
}

input#outlined-number {
  padding: 8px;
  text-align: center;
  /* margin: 0 15px; */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.MuiFormControl-root.MuiTextField-root.pagination-text-field.css-i44wyl {
  margin: 0 10px;
  height: auto;
}

.pagination-wrapper>svg {
  cursor: pointer;
}
.primary-button-color{
  color: #0b41cd;
  border: 1px solid #42a5f5 !important;
}
.secondary-button-color{
  color: #C2BAB5 !important;
  border: 1px solid #C2BAB5 !important;
}

/* .navbar {
    padding: 10px 10px 30px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
} */
.navbar-logo {
  /* margin-top: 10px; */
  width: 4.5rem;
  padding: 0.25rem 0;
}

.main-navbar-logo {
  /* margin-top: 10px; */
  width: 6rem;
  padding: 0.25rem 0;
}

.settings-modal-body {
  padding: 30px;
  max-height: 70vh;
  overflow-y: auto;
}

.navbar-menu {
  width: 70%;
  margin-right: 30px;
  display: flex;
  justify-content: space-evenly;
}

.navbar-links {
  /* margin: 10px 20px; */
  font-size: 1.2rem;
  text-decoration: none;
  /* color: #0B41CD; */
  color: #022366;
  border-radius: 10px;
  padding: 4px 10px;
}

.navbar-links:hover {
  cursor: pointer;
  color: #0b41cd;
  /* background-color: #0B41CD;
    color: #BDE3FF; */
}
li:first-child p:hover {
  text-decoration: underline;
  cursor: pointer;
}

li:first-child p {
  color: #000;
}
.signout-button {
  padding: 5px;
  background-color: #bde3ff;
  color: #1482fa;
  border-radius: 10px;
  border-color: #bde3ff;
  margin-left: 20px;
}

/* Dropdown Button */
.dropbtn {
  background-color: #3498db;
  color: white;
  /* padding: 16px;
  font-size: 16px; */
  border: none;
  cursor: pointer;
}

.user-avatar {
  width: 2rem;
  margin-top: 5px;
  border-radius: 100%;
}

.profile-avatar {
  width: 10rem;
  border-radius: 100%;
}

.profile-options a,
h3 {
  text-decoration: none;
  color: #000;
  cursor: pointer;
}

.documentation-video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.documentation-links {
  height: max-content;
  position: absolute;
  bottom: 0;
  background: white;
}

.documentation-video-player-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.documentation-items {
  height: calc(100% - 150px);
  overflow-y: auto;
}

#dragNode {
  cursor: grab;
}

abbr[title] {
  text-decoration: none;
}

#procedure-table {
  margin: 1rem auto;
  overflow-y: auto;
  overflow-x: auto;
  height: calc(100%-97px);
}

#procedure-table thead th {
  position: sticky;
  top: 0;
}

#procedure-table table {
  border-collapse: collapse;
  width: 100%;
}

/* #procedure-table table, */
#procedure-table td {
  border: 2px solid #aaa;
  padding: 0 0.5rem;
  font-size: small;
}

#procedure-table th {
  border-left: 2px solid #aaa;
  border-bottom: 2px solid #aaa;
  border-right: 2px solid #aaa;
  background: #000;
  color: #fff;
  padding: 0 0.5rem;
  font-size: small;
}

#procedure-table thead {
  position: sticky;
  top: 0;
  z-index: 10;
}

.dataWrapper {
  border-top: 1px solid #c2bab5;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    align-items: center;    
    margin-right: 10px;
    margin-bottom: 10px;
}
.custom-breadcrumb .card-headline:hover{
  text-decoration: underline; 
}
.custom-breadcrumb p{
  display: inline-block;
}
.custom-breadcrumb .card-headline {
  cursor: pointer;
  display: inline-block;
}
.card-headline {
  font-size: 16px;
    font-weight: 600;
}
.filter_wrapper{  
  display: flex;
  align-items: center;
}

.filterFormGroup {
  display: flex;
  align-items: center;
}

.filterFormGroup label.form-label {
  margin-right: 5px;
  width: -webkit-fill-available;
  margin-bottom: 0px;
}

.filterFormGroup select#formFilter {
  margin-right: 5px;
  width: auto;
}
.filter_wrapper button {
  text-transform: none;
}
.card_wrapper, .card_recent_design, .card_design {
  display: flex;
  flex-wrap: wrap;
}
p.card_heading, p.card-headline {
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
}
.card_display:hover {
  border: 1px solid #0B41CD;
}
.simmer_card{
  margin-top: 10px;
}
.card_display {
  /* margin-right : 10px; */
  border: 1px solid #C2BAB5;
  padding: 10px;
  cursor: pointer;
  width: 32%;
  margin-right: 0.6rem;
  font-size: 0.8rem;
  margin-bottom: 10px;
}

.card_display_business_name, .card_display_database_name {
  color: #0B41CD;
  line-height: 24px;
  font-weight: 400;
}

span.card_label {
  font-weight: 700;
}

#main-content-cards {
  font-size: .8rem;
  margin: 1rem;
  padding-bottom: 1rem;
  order: 2;
  width: 90%;
}

::-webkit-scrollbar {
  height: 7px;
  width: 5px;
  border-radius: 2px;
}

::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  background-color: #eee;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #fff;
  outline: 1px solid #999;
  border-radius: 4px;
}

.checkedListItem>* {
  cursor: move;
}

.procedure-page-button {
  background-color: #0B41CD;
  color: #fff;
  border-radius: 2px;
  padding: 6px 12px;
  margin: 0 8px;
}

.procedure-page-button-disabled {
  background-color: #EAE8E5;
  color: #9D948E;
}

.procedure-page-dropdown {
  background-color: #fff !important;
  color: #000 !important;
  border-color: #555 !important;
  width: 200px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.procedure-page-dropdown:hover {
  background-color: #fff !important;
  color: #000 !important;
}

.TabButton {
  font-family: "RocheRegular" !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  text-transform: none !important;
}

.fontMatch {
  font-family: "Roche Sans Light" !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.bp {
  font-family: "Roche Sans Light" !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.subTitle {
  font-family: "Roche Sans Light" !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.intro {
  font-family: "Roche Sans Light" !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}

.accordianQuestion {
  font-family: "Roche Sans" !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 28px !important;
}

.version-dropdown {
  cursor: pointer;
  font-family: "RocheRegular" !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0.2px;
}

.version-dropdown-btn {
  color: #0b41cd !important;
}

.form-check-input:checked {
  background-color: #0b41cd !important;
  border-color: #0b41cd !important;
}
 .version-dropdown-content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 185px;
  width:220px;

}

.main-content-top-heading {
  color: #000;
  /* font-family: "Roche Sans Medium"; */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.2px;
  padding-top:5px;
}

.main-content-top-card {
  display: flex;
  width: 365px;
  height: 155px;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 56px;
  flex-shrink: 0;
  border: 1px solid #C2BAB5;
  background: var(--Primary-white, #FFF);
  margin-top: 19px;
}

.main-content-top-nocard {
  display: flex;
  align-items: center;
  color: #000;
  /* font-family: "Roche Sans Light"; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.2px;

}

.main-content-top-new-to-dva-with-design {

  margin-right: 12px;
}

.main-content-top-new-to-dva-without-design {
  margin-left: 20px;
  margin-right: 12px;
}

.main-content-top-no-design-text {
  font-size: 1rem;
  margin-top: 25px;
  margin-bottom: 25px;
}




.main-content-border {
  width: 100%;
  height: 100%;
  border: 1px #C2BAB5 solid;
}

.main-content-top-request-sandbox {
  cursor: pointer;
  color: var(--fg-informative, #0b41cd);
  font-weight: 700;
}
.main-content-top-request-design-button a {
  color: #fff;
}
.main-content-top-request-design-button {
  display: flex;
  padding: 6px 16px;
  justify-content: center;
  gap: 8px;
  border-radius: 2px;
  background: var(--Primary-Blue-600-roche-blue, #0b41cd);
  box-shadow: 0px 2px 4px 0px rgba(84, 79, 79, 0.16),
    0px 0px 1px 0px rgba(0, 0, 0, 0.32);
  color: #ffffff;
  cursor: pointer;
}
.main-content-top-request-design-button:hover{
  opacity:90%;
}

.main-content-top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-content-top-bar-left {
  margin-right: auto;
}

.main-content-top-bar-right {
  display: flex;
  align-items: center;
  /* margin-right: 30px; */
}

.account-select-label {
  color: #000;
  /* font-family: "Roche Sans Light"; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.3px;
  /* padding-bottom: 16px; */
  padding-right: 11px;

}

.main-content-top-search-bar {
    align-items: center;
    align-self: stretch;
    background: var(--Primary-White, #fff);
    border: 1px solid var(--States-Stroke-Enabled-grey, #ccc);
    display: flex;
    height: 35px;
    margin-right: 1rem;
    padding: 8px 12px;
    border-radius: 5px;
}

.main-content-top-search-icon {
  cursor: pointer;
}

.custom-button {
  width: 15rem;
  height: 4rem;
  font-size: 24px;
}

.table-search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
}

.table-search-input {
  padding-left: 40px; 
  padding-right: 30px; 
  height: 35px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #ccc;
  outline: none; 
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: .3px;
  line-height: 20px;
}

.main-content-top-search-input {
  flex: 1 0 0;
  border: none;
  outline: none;
  color: var(--Typography-Black-Low-Contrast, rgba(0, 0, 0, 0.6));
  font-feature-settings: "clig" off, "liga" off;
  margin-left: 8px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /*142.857%*/
  letter-spacing: 0.3px;
}

.main_page_wrapper{
  display: flex;  
  flex-wrap: wrap;
  position: relative;
}
.main-wrapper {
  margin: .7rem .5rem;
  order: 3;
  position: absolute;
  right: 0;
}
.pageTitle{
  margin: 1rem;
}
.user-profile-content {
  cursor:pointer;
}

.dropdown-container {
  position: absolute!important;
  /* top: 60px!important; */
  right: 5px;
  left: unset!important;
}

.menu-content {
  padding-top: 0!important;
  padding-bottom: 0!important;
}

.vh-100 {
  height: 100vh;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width:100%;
}

.navigation-back-button {
  margin-left: 2rem !important;
  font-size: 60px !important;
  cursor: pointer;
}

.navigation-next-button {
  margin-right: 2rem !important;
  font-size: 60px !important;
  cursor: pointer;
}

.back-button-disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.next-button-disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

/* +++++++++++++++++ Design page CSS +++++++++++++++++ */
.design-page-container {
  width: 100vw;
  height: 100vh;
}

.desin-page-action-bar {
  width: 100%;
  height: 50px;
}

.design-page-navigation-bar {
  width: 100%;
  height: 40px;
}

.design-page-content {
  width: 100%;
  height: calc(100vh-90px);
  overflow: hidden;
}

.login-page-logo {
  position: absolute;
  top: 2rem;
  left: 2rem;
  width: 6rem;
}


#ot-sdk-btn-floating.ot-floating-button {
  bottom: 130px !important;
}

.Toastify__toast-container {
  width: unset !important;
  max-width: 600px;
}

.file-generator-input {
  width: 50vw;
}

.file-generator-input-values {
  width: 38%;
}
